import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-3" }
const _hoisted_4 = { class: "col-md-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_Header = _resolveComponent("Header")!
  const _component_ContactList = _resolveComponent("ContactList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Sidebar, {
          onSearch: _ctx.handleSearch,
          onSort: _ctx.handleSort
        }, null, 8, ["onSearch", "onSort"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Header),
        _createVNode(_component_ContactList, { contacts: _ctx.filteredContacts }, null, 8, ["contacts"])
      ])
    ])
  ]))
}