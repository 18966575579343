<script setup lang="ts">

</script>
<template>
  <header class="bg-light p-3">
    <h1>My Contacts</h1>
  </header>
</template>
<style scoped lang="scss">

</style>
