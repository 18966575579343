import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "list-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContactListItem = _resolveComponent("ContactListItem")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredContacts, (contact) => {
      return (_openBlock(), _createElementBlock("li", {
        key: contact.id,
        class: "list-group-item"
      }, [
        _createVNode(_component_ContactListItem, { contact: contact }, null, 8, ["contact"])
      ]))
    }), 128))
  ]))
}